import React from "react";

export const Footer = (props) => {
  return (
    <div id="footer" className="footer">
      <div className="footer contact-main">
        <div className="overlay">
          <div
            className="container text-center footer-info"
            style={{ paddingTop: 20 }}
          >
            <p>
              &copy; NOI THAT DANG QUANG CO.,LTD. Design by{" "}
              <a href="https://solakatechnology.com/" rel="nofollow">
                Solaka Technology
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};