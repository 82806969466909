import React from "react";
import { Image } from "./image";

export const Header = (props) => {
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = "assets/HSNL_DANG_QUANG.pdf";
    link.setAttribute("download", "HSNL_DANG_QUANG.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <header id="header" className="header main">
      <div className="pb-10">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="content-row">
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="col-md-5 first-column"
                >
                  <div className="intro-text">
                    <Image
                      largeImage={props.data ? props.data.logo : ""}
                      smallImage={props.data ? props.data.logo : ""}
                    />
                  </div>
                  <div
                    className="header-content"
                    style={{ marginTop: 16.6777, paddingBottom: 220 }}
                  >
                    <h1 style={{height: 0, visibility: 'hidden'}}>{props.data ? props.data.title : ""}</h1>
                    <h3>{props.data ? props.data.description : ""}</h3>
                    <h4 className="pt-15">
                      {props.data ? props.data.sub_title : ""}
                    </h4>
                    <div>
                      <button onClick={downloadPdf} className="download-btn">
                        Download Pdf
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-7 second-column"
                >
                  <div
                    style={{
                      position: "relative",
                      top: -300,
                      left: "10%",
                    }}
                  >
                    <div className="image"></div>
                    <div className="image2">
                      <div className="image2_1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
