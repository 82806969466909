import React from "react";

export const Introduce = (props) => {
  return (
    <div id="introduce" className="text-center main">
      <div className="pb-10">
      <div className="introduce">
        <div className="overlay">
          <div className="container">
            <div className="content-row">
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="col-md-5 col-xs-offset-1 first-column"
              >
                <div style={{ textAlign: "left", paddingTop: 50 }}>
                  <h1>01</h1>
                  <h2>{props.data ? props.data.title : ""}</h2>
                  <p>{props.data ? props.data.hello : ""}</p>
                  <p>{props.data ? props.data.content_1 : ""}</p>
                  <p>{props.data ? props.data.content_2 : ""}</p>
                  <p>{props.data ? props.data.content_3 : ""}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  left: 100,
                  bottom: 50,
                }}
                className="col-md-6 second-column"
              >
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 100,
                    paddingBottom: 50,
                  }}
                >
                  <div className="image"></div>
                  <div className="image2">
                    <div className="image2_1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
