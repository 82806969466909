import React from "react";
import { Image } from "./image";

export const Product = (props) => {
  return (
    <div id="product" className="main">
      <div className="pb-10">
        <div className="product">
          <div className="overlay">
            <div style={{ height: 950 }} className="container">
              <div className="content-row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3%",
                  }}
                  className="col-md-6 first-column"
                >
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "10%",
                      paddingBottom: "10%",
                    }}
                  >
                    <h1>09</h1>
                    <h2>{props.data ? props.data.title : ""}</h2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-4 second-column"
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="image"></div>
                    <div className="image_background"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: 0 }} className="col-md-12 product_list" id="row">
        {props.data && props.data.products
          ? props.data.products.map((d, i) => (
              <div className="pb-10">
                <div className="product_item main">
                  <div className="overlay">
                    <div className="container">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: '20px'
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="product-title"
                              ></div>
                              <h3>{d?.name}</h3>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 30,
                              }}
                            >
                              {d?.img?.length > 1 ? (
                                  <div
                                    className="img-grid"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "90%"
                                    }}
                                  >
                                    <div
                                      className="img"
                                      style={{ width: "55%", margin: 10 }}
                                    >
                                      <Image
                                        largeImage={d?.img[0]}
                                        smallImage={d?.img[0]}
                                      />
                                    </div>
                                    <div
                                      className="img-grid-2"
                                      style={{
                                        width: "40%",
                                        margin: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        className="img"
                                        style={{ width: "90%" }}
                                      >
                                        <Image
                                          largeImage={d?.img[1]}
                                          smallImage={d?.img[1]}
                                        />
                                      </div>
                                      <div
                                        className="img"
                                        style={{ width: "90%" }}
                                      >
                                        <Image
                                          largeImage={d?.img[2]}
                                          smallImage={d?.img[2]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                              ) : (
                                <div className="img" style={{ width: "90%" }}>
                                  <Image
                                    largeImage={d?.img}
                                    smallImage={d?.img}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "loading"}
      </div>
    </div>
  );
};
