import React from "react";
import { Image } from "./image";

export const Contact = (props) => {
  return (
    <div id="contact" className="main">
      <div className="pb-10">
        <div className="contact contact-main">
          <div className="overlay">
            <div className="container">
              <div
                className="contact-div"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
                <div className="contact-img" style={{ paddingLeft: 200 }}>
                  <Image
                    largeImage={props.data ? props.data.img1 : ""}
                    smallImage={props.data ? props.data.img1 : ""}
                  />
                </div>
                <div
                  className="contact-img"
                  style={{ paddingLeft: 10, width: "42%" }}
                >
                  <Image
                    largeImage={props.data ? props.data.img2 : ""}
                    smallImage={props.data ? props.data.img2 : ""}
                  />
                </div>
                <div
                  className="contact-img"
                  style={{ paddingLeft: 10, width: "70%" }}
                >
                  <Image
                    largeImage={props.data ? props.data.img3 : ""}
                    smallImage={props.data ? props.data.img3 : ""}
                  />
                </div>
              </div>
              <div
                className="contact-div"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className="contact-img"
                  style={{ paddingLeft: 10, width: "60%" }}
                >
                  <Image
                    largeImage={props.data ? props.data.img4 : ""}
                    smallImage={props.data ? props.data.img4 : ""}
                  />
                </div>
                <div
                  className="contact-img"
                  style={{ paddingLeft: 10, width: "40%" }}
                >
                  <Image
                    largeImage={props.data ? props.data.img5 : ""}
                    smallImage={props.data ? props.data.img5 : ""}
                  />
                </div>
                <div
                  className="contact-img"
                  style={{
                    paddingLeft: 10,
                    width: "68%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ paddingTop: 70 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 0,
                      }}
                      className="col-md-12"
                    >
                      <div
                        style={{
                          height: "25px",
                          width: "25px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          display: "inline-block",
                          marginRight: "10px",
                          marginBottom: 20,
                        }}
                      ></div>
                      <h4>{props.data ? props.data.title : ""}</h4>
                    </div>
                    <p>
                      Email:{" "}
                      <a
                        style={{ color: "white", textDecoration: "underline" }}
                        href={`mailto:${props.data ? props.data.email : ""}`}
                      >
                        {props.data ? props.data.email : ""}
                      </a>
                    </p>
                    <p>Phone: {props.data ? props.data.phone : ""}</p>
                    <p>
                      MST: {props.data? props.data.mst : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
