import React from "react";
import { Image } from "./image";

export const Team = (props) => {
  return (
    <div id="team">
      <div className="pb-10">
        <div className="team team_col1">
          <div className="overlay">
            <div style={{ height: 950 }} className="container">
              <div className="content-row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3%",
                  }}
                  className="col-md-6 first-column"
                >
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "10%",
                      paddingBottom: "10%",
                    }}
                  >
                    <h1>07</h1>
                    <h2>{props.data ? props.data.title : ""}</h2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-4 second-column"
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="image"></div>
                    <div className="image_background"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-10">
        <div className="team team_col1">
          <div className="overlay">
            <div style={{ height: 950 }} className="container">
              <div className="content-row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3%",
                  }}
                  className="col-md-6 first-column"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                    className="col-md-12"
                  >
                    <div style={{ textAlign: "left", paddingTop: "15%" }}>
                      <Image
                        largeImage={props.data ? props.data.image_1 : ""}
                        smallImage={props.data ? props.data.image_1 : ""}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-4 second-column"
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="image"></div>
                    <div className="image_background"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="team team_col2" id="team">
          <div style={{ height: 800 }} className="container">
            <div
              style={{
                marginLeft: "3%",
                display: "flex",
                alignItems: "center",
              }}
              className="col-md-8 section-title"
            >
              <div
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  backgroundColor: "red",
                  display: "inline-block",
                  marginRight: "10px",
                  marginTop: "30px",
                }}
              ></div>
              <h3>NHÂN SỰ CHỦ CHỐT</h3>
            </div>
            <div
              className="col-md-12 team-main"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="team-list" style={{ display: "flex" }} id="row">
                {props.data && props.data.teams
                  ? props.data.teams.map((d, i) => (
                      <div
                        key={`${d.name}-${i}`}
                        className="col-md-3 col-sm-6 team"
                        style={{ textAlign: "center" }}
                      >
                        <div className="thumbnail">
                          {" "}
                          <img src={d.icon} alt="..." className="team-icon" />
                          <img src={d.img} alt="..." className="team-img" />
                          <div className="caption">
                            <h4>{d.name}</h4>
                            <p>{d.job}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  : "loading"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
