import React from "react";

export const Mission = (props) => {
  return (
    <div id="mission" className="main">
      <div className="pb-10">
      <div className="mission">
        <div className="overlay">
          <div style={{ height: 950 }} className="container">
            <div className="content-row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "3%",
                }}
                className="col-md-6 first-column"
              >
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "10%",
                    paddingBottom: "10%",
                  }}
                >
                  <h1>05</h1>
                  <h2>{props.data ? props.data.title : ""}</h2>
                  <p>{props.data ? props.data.content_1 : ""}</p>
                  <p>{props.data ? props.data.content_2 : ""}</p>
                  <p>{props.data ? props.data.content_3 : ""}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                className="col-md-4 second-column"
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="image"></div>
                  <div className="image_background"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
