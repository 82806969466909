import React from "react";
import { Image } from "./image";

export const Customer = (props) => {
  return (
    <div id="customer" className="main">
      <div className="pb-10">
        <div className="customer customer_col1">
          <div className="overlay">
            <div style={{ height: 950 }} className="container">
              <div className="content-row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3%",
                  }}
                  className="col-md-6 first-column"
                >
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "10%",
                      paddingBottom: "10%",
                    }}
                  >
                    <h1>08</h1>
                    <h2>{props.data ? props.data.title : ""}</h2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-4 second-column"
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="image"></div>
                    <div className="image_background"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="customer customer_col2">
          <div className="overlay">
            <div style={{ height: 950 }} className="container">
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3%",
                  }}
                  className="col-md-5 first-column"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                    className="col-md-12"
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="col-md-12"
                    >
                      <div
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          display: "inline-block",
                          marginRight: "10px",
                          marginTop: "30px",
                        }}
                      ></div>
                      <h3>{props.data ? props.data.title : ""}</h3>
                    </div>
                    <div
                      className="div-image"
                      style={{ textAlign: "left", width: "160%", zIndex: 222 }}
                    >
                      <Image
                        largeImage={props.data ? props.data.image_1 : ""}
                        smallImage={props.data ? props.data.image_1 : ""}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-6 second-column"
                >
                  <div
                    className="test"
                    style={{
                      textAlign: "left",
                      paddingTop: "15%",
                      position: "absolute",
                      zIndex: 1,
                      right: 0,
                    }}
                  >
                    <Image
                      largeImage={props.data ? props.data.background : ""}
                      smallImage={props.data ? props.data.background : ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="customer customer_col2">
          <div className="overlay">
            <div style={{ height: 950 }} className="container">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3%",
                  }}
                  className="col-md-5 first-column"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                    className="col-md-12"
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="col-md-12"
                    >
                      <div
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          display: "inline-block",
                          marginRight: "10px",
                          marginTop: "30px",
                        }}
                      ></div>
                      <h3>{props.data ? props.data.title : ""}</h3>
                    </div>
                    <div
                      className="div-image"
                      style={{ textAlign: "left", width: "160%", zIndex: 222 }}
                    >
                      <Image
                        largeImage={props.data ? props.data.image_2 : ""}
                        smallImage={props.data ? props.data.image_2 : ""}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-6 second-column"
                >
                  <div
                    className="test"
                    style={{
                      textAlign: "left",
                      paddingTop: "15%",
                      position: "absolute",
                      zIndex: 1,
                      right: 0,
                    }}
                  >
                    <Image
                      largeImage={props.data ? props.data.background : ""}
                      smallImage={props.data ? props.data.background : ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="customer customer_col2">
          <div className="overlay">
            <div style={{ height: 950 }} className="container">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "3%",
                  }}
                  className="col-md-5 first-column"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                    className="col-md-12"
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="col-md-12"
                    >
                      <div
                        style={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          display: "inline-block",
                          marginRight: "10px",
                          marginTop: "30px",
                        }}
                      ></div>
                      <h3>NHÀ THẦU CHIẾN LƯỢC</h3>
                    </div>
                    <div
                      className="div-image"
                      style={{ textAlign: "left", width: "160%", zIndex: 222 }}
                    >
                      <Image
                        largeImage={props.data ? props.data.image_3 : ""}
                        smallImage={props.data ? props.data.image_3 : ""}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="col-md-6 second-column"
                >
                  <div
                    className="test"
                    style={{
                      textAlign: "left",
                      paddingTop: "15%",
                      position: "absolute",
                      zIndex: 1,
                      right: 0,
                    }}
                  >
                    <Image
                      largeImage={props.data ? props.data.background : ""}
                      smallImage={props.data ? props.data.background : ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
