import React, { useEffect, useState } from "react";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Contact } from "./components/contact";
import { Customer } from "./components/customer";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { History } from "./components/history";
import { Introduce } from "./components/introduce";
import { Legal } from "./components/legal";
import { Mission } from "./components/mission";
import { Product } from "./components/product";
import { Team } from "./components/team";
import { Value } from "./components/value";
import { Vision } from "./components/vision";
import JsonData from "./data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <Introduce data={landingPageData.Introduce} />
      <Legal data={landingPageData.Legal_Info} />
      <History data={landingPageData.History} />
      <Vision data={landingPageData.Vision} />
      <Mission data={landingPageData.Mission} />
      <Value data={landingPageData.Value} />
      <Team data={landingPageData.Team} />
      <Customer data={landingPageData.Customer} />
      <Product data={landingPageData.Product} />
      <Contact data={landingPageData.Contact} />
      <Footer data={{}} />
    </div>
  );
};

export default App;
